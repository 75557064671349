<template>
  <div>
    <div
      v-if="!rows.length"
      class="d-flex justify-content-center mb-1"
    >
      <div
        class="text-center"
      >
        <h5>{{ $t('promotions.nopromotions') }}</h5>
        <p>{{ $t('promotions.addpromotion') }}</p>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="primary"
          class="btn-icon mb-1"
          :to="{ path: `/users/${$route.params.userId}/sites/${$route.params.siteId}/promotions/create`}"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
          {{ $t('promotions.promotionadd') }}
        </b-button>
      </div>
    </div>

    <div
      v-else
      class="promotions-table"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between align-items-center mb-1">
        <div>
          <strong>{{ $t('promotions.promotiontotal') }}: {{ rows.length }}</strong>
        </div>
        <div class="d-flex">
          <b-form-group class="mb-0 mr-1">
            <div class="d-flex align-items-center">
              <label class="mr-1 mb-0">{{ $t('message.seachLabel') }}</label>
              <b-input-group>
                <b-form-input
                  ref="searchInput"
                  :value="searchTerm"
                  :placeholder=" $t('form.search')"
                  type="text"
                  class="d-inline-block"
                  @keyup.enter="searchTerm = $event.target.value"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="searchTerm = $refs.searchInput.vModelValue"
                  >
                    <feather-icon
                      icon="SearchIcon"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-form-group>
          <b-button-toolbar justify>
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                class="btn-icon"
                :to="{ path: `/users/${rows[0].user_id}/sites/${rows[0].site_id}/promotions/create`}"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                {{ $t('promotions.promotionadd') }}
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Status'"
            class="text-nowrap"
          >
            {{ $t('message.tableHeader.status') }}
          </span>
          <span
            v-else-if="props.column.label ==='Name'"
            class="text-nowrap"
          >
            {{ $t('message.tableHeader.name') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('message.tableHeader.action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
          >
            <b-form-checkbox
              :checked="props.row.status === 'active' ? true : false"
              name="check-button"
              switch
              @change="changeStatus(props.row._id)"
            />
          </span>

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'title'"
            class="text-nowrap"
          >
            <span class="d-flex justify-content-between">
              <router-link :to="'/users/'+ props.row.user_id + '/sites/'+ props.row.site_id + '/promotions/'+ props.row._id">
                {{ props.row.title }}
              </router-link>
              <b-button
                v-b-tooltip.hover.top="`Delete ${props.row.title}`"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-sm btn-icon"
                @click="deletePromotion(props.row._id, props.row.title)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </span>

          <!-- Column: Action -->
          <span
            v-else-if="props.column.field === 'action'"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :to="'/users/'+ $route.params.userId +'/sites/'+ $route.params.siteId +'/promotions/'+ props.row._id + '/edit/'"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>{{ $t('form.edit') }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('message.pagelength') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','20','40']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BDropdown, BDropdownItem, BFormCheckbox, VBTooltip, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  components: {
    BButton,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Status',
          field: 'status',
          width: '3%',
        },
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Action',
          width: '5%',
          field: 'action',
          sortable: false,
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {},
  methods: {
    changeStatus(id) {
      const promotion = this.rows.find(p => p._id === id)
      promotion.status = promotion.status === 'inactive' ? 'active' : 'inactive'
      if (promotion.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setPromotion(id, promotion)
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            promotion.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setPromotion(id, promotion)
        })
      }
    },
    deletePromotion(id, name) {
      this.$swal({
        title: `Delete ${name}?`,
        text: 'Do you really want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Delete',
            text: 'Successfully deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          useJwt.deletePromotion(id).then(() => this.$emit('refresh'))
        }
      })
    },
  },
}
</script>
<style>
.promotions-table td:first-child,
.promotions-table td:last-child {
  text-align: center!important;
}
</style>
