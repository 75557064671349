<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </div>
    <template v-else>
      <b-card>
        <SiteToolbar
          v-if="Object.keys(site).length !== 0"
          :site="site"
          closing
        />
        <hr>
        <promotions-table
          :rows="rows"
          @refresh="getSitePromotions()"
        />
      </b-card>
    </template>
  </div>
</template>

<script>
import SiteToolbar from '@/views/dashboard/sites/SiteToolbar.vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import PromotionsTable from './PromotionsTable.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BSpinner,
    PromotionsTable,
    UserToolbar,
    SiteToolbar,
  },
  data() {
    return {
      loading: true,
      authUserData: getUserData(),
      account: {},
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      site: {},
      rows: [],
    }
  },
  created() {
    this.getUserData()
  },
  methods: {
    async getSitePromotions() {
      this.rows = []
      const params = {
        site_id: this.$route.params.siteId,
      }
      const responseData = await useJwt.getSitePromotions(params)
      this.rows = responseData.data.promotions || []
      this.loading = false
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.pageTitle = this.account.name
      this.pageSubtitle = this.account.email
      this.getSiteData()
    },
    async getSiteData() {
      const responseData = await useJwt.getSite(this.$route.params.siteId)
      this.site = responseData.data.site || {}
      this.getSitePromotions()
      this.setBreabcrumbs()
    },
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('page.menu.users'),
          to: '/users',
        },
        {
          text: this.pageTitle,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: this.$t('page.user.sites'),
          to: `/users/${this.$route.params.userId}/sites`,
        },
        {
          text: this.site.title,
          to: `/users/${this.$route.params.userId}/sites/${this.$route.params.siteId}`,
        },
        {
          text: this.$t('Promotions'),
          active: true,
        },
      ]
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
